import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { AuthenticationService } from "services/authentication-service";

@autoinject()
export class PasswordResetPage {
  router: Router;
  private _authenticationService: AuthenticationService;

  passwordResetMessage: string = "";
  passwordResetPassword: string = "";
  passwordResetPasswordRepeat: string = "";
  token: string = "";
  passwordResetEmail: string = "";
  attemptingPasswordReset: boolean = false;

  constructor(authenticationService: AuthenticationService, router: Router) {
    this._authenticationService = authenticationService;
    this.router = router;
  }

  activate(params) {
    this.token = params.token;
    this.passwordResetEmail = params.email;
  }

  async resetPassword() {
    if (this.passwordResetPassword !== this.passwordResetPasswordRepeat) {
      this.passwordResetMessage = "Passwords do not match";
      return;
    }
    this.attemptingPasswordReset = true;

    var resetMessage = await this._authenticationService.resetPassword(
      this.passwordResetPassword,
      this.passwordResetPasswordRepeat,
      this.token,
      this.passwordResetEmail
    );

    this.passwordResetMessage = resetMessage;

    this.attemptingPasswordReset = false;
  }
  navigateToLogin() {
    this.router.navigateToRoute("login");
  }
}
