import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IAppointment, IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { ISubscription, Router } from "aurelia-router";
import moment from "moment";
import { DialogService } from "aurelia-dialog";
import Swal from "sweetalert2";
import { CancelAppointmentDialog } from "modals/cancel-appointment";
import { CompleteMemberAppointmentDialog } from "modals/complete-member-appointment";

@connectTo<State>()
@autoinject()
export class Appointments {
  _stateManager: StateManager;
  _router: Router;
  _dialogService: DialogService;

  appointments: IAppointment[];
  isLoadingTableData: boolean = false;
  todaysAppointments: IAppointment[] = [];
  activeSubscriptions: ISubscription[] = [];

  birthdayInt: number = 7;
  clientBirthdays: IUser[] = [];

  constructor(
    stateManager: StateManager,
    router: Router,
    dialogService: DialogService
  ) {
    this._stateManager = stateManager;
    this._router = router;
    this._dialogService = dialogService;
  }

  stateChanged(state: State) {
    this.appointments = state.todaysAppointments;
    this.clientBirthdays = state.clientBirthdays;
    this.isLoadingTableData = false;

    if (this.appointments) {
      this.getTodaysAppointments();
    }
  }

  attached() {
    this.isLoadingTableData = true;
    this._stateManager.getTodaysAppointments();
    this._stateManager.getActiveSubscriptionsAsync();
    this._stateManager.getClientBirthdaysAsync(this.birthdayInt);
  }

  navigateToPatient(user: IUser) {
    this._router.navigateToRoute("patient", { userId: user.userId });
  }

  generateComortPreferences(user: IUser) {
    var comfortPreferences = "";
    if (user.soundCancellingHeadphones)
      comfortPreferences += "Sound Cancelling Headphones, ";
    if (user.netflix) comfortPreferences += "Netflix, ";
    if (user.lemongrassDiffuser) comfortPreferences += "Lemongrass Diffuser, ";
    if (user.weightedBlanket) comfortPreferences += "Weighted Blanket, ";
    if (user.stressBalls) comfortPreferences += "Stress Balls, ";
    if (user.heatedBlanket) comfortPreferences += "Heated Blanket, ";
    if (user.latte) comfortPreferences += "Latte, ";
    if (user.waterBottle) comfortPreferences += "Water Bottle, ";
    if (user.eyeMask) comfortPreferences += "Eye Mask, ";

    //remove last comma from the list if there is one.
    if (comfortPreferences.length > 0)
      comfortPreferences = comfortPreferences.slice(0, -2);

    return comfortPreferences;
  }

  appointmentIsToday(appointment) {
    var appointmentDate = moment.utc(appointment.date).local();
    var today = moment();

    return appointmentDate.isSame(today, "day");
  }

  async completeAppointment(appointment: IAppointment) {
    if (appointment.user.activeSubscription == null) {
      Swal.fire({
        title: "Complete Appointment?",
        text: "Are you sure you want to complete this appointment?",
        icon: "success",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Mark Complete",
        confirmButtonColor: "#40684e",
        denyButtonText: "Mark No Show",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          appointment.isCompleted = true;
          await this._stateManager.updateAppointmentAsync(appointment);
          await this._stateManager.getTodaysAppointments();
        }
        if (result.isDenied) {
          appointment.isNoShow = true;
          await this._stateManager.updateAppointmentAsync(appointment);
          await this._stateManager.getTodaysAppointments();
        }
      });
    } else {
      this._dialogService
        .open({
          viewModel: CompleteMemberAppointmentDialog,
          model: appointment,
        })
        .whenClosed(async (response) => {
          if (!response.wasCancelled) {
          } else {
          }
        });
    }
  }
  cancelAppointment(appointment) {
    this._dialogService
      .open({
        viewModel: CancelAppointmentDialog,
        model: appointment,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }
  getTodaysAppointments() {
    this.todaysAppointments = this.appointments.filter((appointment) =>
      this.appointmentIsToday(appointment)
    );
  }
  
  getBadgeColor(membershipString) {
    switch (membershipString) {
      case "Founder":
        return "ro-orange";
      case "Gold":
        return "ro-gold";
      case "Silver":
        return "ro-green";
      case "Bronze":
        return "ro-blue";
      default:
        return "primary";
    }
  }
}
