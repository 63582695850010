import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IAppointment, IAppointmentRequest, IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { Router } from "aurelia-router";
import { DialogService } from "aurelia-dialog";
import { NewAppointmentFromRequestDialog } from "modals/new-appointment-from-request";
import Swal from "sweetalert2";

@connectTo<State>()
@autoinject()
export class Patient {
  _stateManager: StateManager;
  @bindable _router: Router;
  dialogService: DialogService;

  patient: IUser;
  appointments: IAppointment[] = [];
  editingUserInfo: boolean = false;

  constructor(stateManager: StateManager, dialogService: DialogService) {
    this._stateManager = stateManager;
    this.dialogService = dialogService;
  }

  stateChanged(state) {
    this.patient = state.patient;
    if (state.patient) {
      this.sortPatientAppointments();
    }
  }

  async activate(params, routeConfig, navigationInstruction) {
    await this._stateManager.getPatientAsync(params.userId);
  }

  deleteAppointmentRequest(appointmentRequest: IAppointmentRequest) {
    Swal.fire({
      title: `Confirm Deletion`,
      text: `Are you sure you want to delete ${appointmentRequest.user.firstName}'s appointment request?`,
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Yes, Delete`,
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this._stateManager.deleteAppointmentRequest(appointmentRequest);
        await this._stateManager.getPatientAppointmentRequests();
      }
    });
  }

  createAppointment(appointmentRequest: IAppointmentRequest) {
    this.dialogService
      .open({
        viewModel: NewAppointmentFromRequestDialog,
        model: appointmentRequest,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
          await this._stateManager.postAppointmentAsync(response.output);
          await this._stateManager.getPatientAppointmentRequests();
        } else {
        }
      });
  }

  sortPatientAppointments() {
    if (!this.patient || !this.patient.appointments) return;

    this.appointments = this.patient.appointments.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
  }

  getTextColor(appointment) {
    if (appointment.isCancelled) {
      return "text-danger";
    }
    if (appointment.isCompleted) {
      return "text-success";
    }
    return "";
  }

  edit() {
    this.editingUserInfo = true;
  }

  async save() {  
    this._stateManager.updatePatientAsync(this.patient);    
    this.editingUserInfo = false;
  }
}
