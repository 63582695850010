import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "services/authentication-service";
import { IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import Swal from "sweetalert2";

@autoinject()
@connectTo<State>()
export class LoginPage {
  router: Router;
  private _authenticationService: AuthenticationService;
  stateManager: StateManager;
  user: IUser;

  showingErrors: boolean = false;

  loginEmail: string = "";
  loginPassword: string = "";
  loginError: string = "";
  attemptingLogin: boolean = false;

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    stateManager: StateManager
  ) {
    this._authenticationService = authenticationService;
    this.router = router;
    this.stateManager = stateManager;
  }

  activate() {
    if (this.user && this.user.isAdmin) {
      this.router.navigateToRoute("today");
    }
    if (this.user && !this.user.isAdmin) {
      this.router.navigateToRoute("dashboard");
    }
  }

  stateChanged(state: State) {
    this.user = state.user;
  }

  async login() {
    if (!this.loginEmail || !this.loginPassword) {
      this.loginError = "Please enter an email and password";
      return;
    }

    this.attemptingLogin = true;
    var loginSucceeded = null;

    if (this.loginEmail && this.loginPassword) {
      loginSucceeded = await this._authenticationService.loginAsync(
        this.loginEmail,
        this.loginPassword
      );
    }

    if (loginSucceeded == "Please Confirm Your Email Address") {
      this.loginError =
        "Please confirm your email address. Click below to send a new confirmation email.";
    }

    if (loginSucceeded == "Unknown User") {
      this.loginError = "Invalid email or password. Please try again.";
      this.loginPassword = "";
    }
    if (loginSucceeded == "Name Taken") {
      this.loginError = "That email is already in use. Please try again.";
      this.loginPassword = "";
    }

    this.attemptingLogin = false;
    this.loginPassword = "";

    const user: IUser = await this.stateManager.getUserAsync();
    if (user.isAdmin) {
      this.router.navigateToRoute("today");
    } else {
      this.router.navigateToRoute("dashboard");
    }
  }

  sendPasswordResetEmail() {
    Swal.fire({
      title: "Enter Your Email Address",
      input: "email",
      inputPlaceholder: "janero@gmail.com",
      confirmButtonColor: "#40684e",
      showCancelButton: true,
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        this._authenticationService.sendPasswordResetEmail(result.value);
        Swal.fire({
          title: "Password Reset",
          text: "If that email has an account with us, you will receive a password reset link shortly.",
          icon: "success",
          iconColor: "#40684e",
          confirmButtonColor: "#40684e",
        });
      }
    });
  }

  sendConfirmationEmail() {
    Swal.fire({
      title: "Enter Your Email Address",
      input: "email",
      inputPlaceholder: "janero@gmail.com",
      confirmButtonColor: "#40684e",
      showCancelButton: true,
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        this._authenticationService.sendConfirmationEmail(result.value);
        Swal.fire({
          title: "Account Confirmation",
          text: "If that email has an account with us, you will receive a confirmation email shortly.",
          icon: "success",
          iconColor: "#40684e",
        });
      }
    });
  }

  navigateToRegisterPage() {
    this.router.navigateToRoute("register");
  }
}
