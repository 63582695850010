import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { ISubscription, Router } from "aurelia-router";
import { DialogService } from "aurelia-dialog";

@connectTo<State>()
@autoinject()
export class PatientAppointments {
  _stateManager: StateManager;
  _router: Router;
  _dialogService: DialogService;
  activeSubscriptions: ISubscription[];
  isLoadingTableData: boolean = false;

  constructor(
    stateManager: StateManager,
    router: Router,
    dialogService: DialogService
  ) {
    this._stateManager = stateManager;
    this._router = router;
    this._dialogService = dialogService;
  }

  stateChanged(state: State) {
    this.activeSubscriptions = state.activeSubscriptions;
    this.isLoadingTableData = false;
  }

  attached() {
    this.isLoadingTableData = true;
    this._stateManager.getActiveSubscriptionsAsync();
  }

  navigateToPatient(user: IUser) {
    this._router.navigateToRoute("patient", { userId: user.userId });
  }
}
