import { autoinject, TaskQueue } from "aurelia-framework";
import { Router } from "aurelia-router";
import { AuthenticationService } from "services/authentication-service";
import Swal from "sweetalert2";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

@autoinject()
export class RegisterPage {
  router: Router;
  private _authenticationService: AuthenticationService;
  taskQueue: TaskQueue;
  flatPickerObject: any;

  showingErrors: boolean = false;
  attemptingRegistration: boolean = false;

  registerFirstName: string = "";
  firstNameError: string = "";

  registerLastName: string = "";
  lastNameError: string = "";

  registerEmail: string = "";
  emailError: string = "";

  registerPhoneNumber: string = "";
  phoneNumberError: string = "";

  registerPassword: string = "";
  passwordError: string = "";

  registerPasswordRepeat: string = "";
  passwordRepeatError: string = "";

  registerWantsNewsletter: boolean = true;
  registerServerMessage: string = "";

  birthday: Date;
  birthdayError: string = "";

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    taskQueue: TaskQueue
  ) {
    this._authenticationService = authenticationService;
    this.router = router;
    this.taskQueue = taskQueue;
  }

  attached() {
    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
  }

  createDatePicker() {
    var htmlElement = document.getElementById("birthday-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      dateFormat: "F d",
      onChange: (date) => {
        this.birthday = date[0];
      },
    });
  }

  async registerNewUser() {
    if (!this.registerFirstName) {
      this.firstNameError = "Please enter your first name";
    } else {
      this.firstNameError = "";
    }

    if (!this.registerLastName) {
      this.lastNameError = "Please enter your last name";
    } else {
      this.lastNameError = "";
    }

    this.checkEmailAddress();

    this.checkPhoneNumber();

    this.checkPasswords();

    if (
      this.firstNameError ||
      this.lastNameError ||
      this.emailError ||
      this.phoneNumberError ||
      this.passwordError ||
      this.passwordRepeatError
    ) {
      this.showingErrors = true;
      return;
    }

    this.attemptingRegistration = true;

    var registerResult = await this._authenticationService.registerAsync(
      this.registerFirstName,
      this.registerLastName,
      this.registerEmail,
      this.registerPhoneNumber,
      this.registerPassword,
      this.registerPasswordRepeat,
      this.registerWantsNewsletter,
      this.birthday
    );

    if (registerResult == "Email Taken") {
      this.registerServerMessage = "Email already in use";
      this.attemptingRegistration = false;
    }
    if (registerResult == "Failed To Create User") {
      this.registerServerMessage =
        "Failed to create account. Please try again later";
      this.attemptingRegistration = false;
    }

    if (registerResult === "Success") {
      this.registerServerMessage = "Account Successfully Created!";
      this.attemptingRegistration = false;
      //set delay for one second
      setTimeout(() => {
        this.navigateToLoginPage();
      }, 1000);
    }
  }

  toggleNewsletter() {
    this.registerWantsNewsletter = !this.registerWantsNewsletter;
  }

  checkPhoneNumber() {
    if (!this.registerPhoneNumber) {
      this.phoneNumberError = "Please enter your phone number";
      return;
    }

    var phoneRegex = /^[0-9]{10}$/;
    if (this.registerPhoneNumber.match(phoneRegex)) {
      this.phoneNumberError = "";
    } else {
      this.phoneNumberError = "Please enter a valid 10-digit phone number";
    }
  }

  checkEmailAddress() {
    if (!this.registerEmail) {
      this.emailError = "Please enter your email address";
      return;
    }

    if (
      this.registerEmail.includes("@") === false ||
      this.registerEmail.includes(".") === false
    ) {
      this.emailError = "Please enter a valid email address";
      return;
    }
    this.emailError = "";
  }

  checkPasswords() {
    if (!this.registerPassword) {
      this.passwordError = "Please enter a password";
      return;
    }
    if (!this.registerPasswordRepeat) {
      this.passwordRepeatError = "Please repeat your password";
      return;
    }
    if (this.registerPassword !== this.registerPasswordRepeat) {
      this.passwordRepeatError = "Passwords do not match";
      return;
    }

    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,24}$/;
    // var decimal =
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (this.registerPassword.match(regularExpression)) {
      this.passwordError = "";
    } else {
      this.passwordError =
        "Password must contain at least 8 characters. One uppercase, one lowercase, one number and one special character";
      return;
    }

    this.passwordError = "";
  }

  navigateToLoginPage() {
    this.router.navigateToRoute("login");
  }

  sendConfirmationEmail() {
    Swal.fire({
      title: "Enter your Email Address",
      input: "email",
      inputPlaceholder: "janero@gmail.com",
      confirmButtonColor: "#40684e",
    }).then((result) => {
      if (result.value) {
        this._authenticationService.sendConfirmationEmail(result.value);
        Swal.fire({
          title: "Account Confirmation",
          text: "If that email has an account with us, you will receive a confirmation email shortly.",
          icon: "success",
          iconColor: "#40684e",
        });
      }
    });
  }
}
