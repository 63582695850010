import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "services/authentication-service";
import { IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import Swal from "sweetalert2";

@autoinject()
@connectTo<State>()
export class HomePage {
  router: Router;
  user: IUser;
  stateManager: StateManager;
  _authenticationService: AuthenticationService;

  constructor(
    router: Router,
    stateManager: StateManager,
    authenticationService: AuthenticationService
  ) {
    this.router = router;
    this.stateManager = stateManager;
    this._authenticationService = authenticationService;
  }

  activate() {}

  attached() {
    if (this.user && this.user.isAdmin) {
      this.router.navigate("today");
    } else if (this.user && !this.user.isAdmin) {
      this.router.navigate("dashboard");
    }
  }

  stateChanged(state: State) {
    this.user = state.user;
  }

  navigateToRegister() {
    this.router.navigate("register");
  }
  navigateToLogin() {
    this.router.navigate("login");
  }
  navigateToBookNow() {
    this.router.navigate("book-now");
  }
}
